import { Component } from '@angular/core'

import { NavigationService } from './shared/services/navigation.service'
import { TranslateService } from './shared/services/translate.service'
import { AuthService } from '@peritos/module-builder'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  /**
   * constructor
   * @param navigationService Navigation Service
   * @param translateService Translate Service
   * @param authService Auth Service
   */
  constructor(
    _navigationService: NavigationService,
    translateService: TranslateService,
    authService: AuthService
  ) {
    // Initialize auth service
    authService.init()

    // Initialize translate service
    translateService.init()
  }
}
