// Angular
import { AppComponent } from './app.component'
import { NgModule, isDevMode } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module'
import { ServiceWorkerModule } from '@angular/service-worker'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http'

// NgRx
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import {
  reducers,
  AuthEffects,
  metaReducers,
  LocalStorageEffects
} from '@peritos/module-builder'

// Data Store API Client Library
import { peritosStoreModule } from '@peritos/api-client'

// Locale
import { LOCALE_ID } from '@angular/core'
import localeEs from '@angular/common/locales/es'
import { registerLocaleData } from '@angular/common'

// Registra el idioma español
registerLocaleData(localeEs)

// Satinel UI Library
import { SatinelFormModule } from '@satinel-system/form'

// PrimeNG
import { ToastModule } from 'primeng/toast'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { ConfirmationService, MessageService } from 'primeng/api'

// Interceptors
import { AuthInterceptor } from './shared/interceptors/auth.interceptor'

// Transloco
import { TranslocoRootModule } from './transloco-root.module'

// Environment
import { environment } from '../environments/environment'

const primeng = [ToastModule, ConfirmDialogModule]

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      // Comprobaciones en tiempo de ejecución para los estados de ngrx
      runtimeChecks: {
        // Comprobaciones de inmutabilidad
        strictStateImmutability: true,
        strictActionImmutability: true,
        // Comprobaciones de serialización
        strictStateSerializability: true,
        strictActionSerializability: true,
        // La acción se ejecuta dentro de la zona de Angular
        strictActionWithinNgZone: true,
        // La acción es única
        strictActionTypeUniqueness: true
      }
    }),
    peritosStoreModule.forRoot({
      apiUrl: environment.api.url
    }),
    EffectsModule.forRoot([AuthEffects, LocalStorageEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    StoreRouterConnectingModule.forRoot(),
    ReactiveFormsModule,
    SatinelFormModule.forRoot(),
    TranslocoRootModule,
    ...primeng
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es' },
    MessageService,
    ConfirmationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {}
