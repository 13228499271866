import { HttpClient } from '@angular/common/http'
import {
  Translation,
  TranslocoLoader,
  translocoConfig,
  TranslocoModule,
  provideTransloco,
  getBrowserLang,
  TranslocoService
} from '@jsverse/transloco'
import {
  TranslocoLocaleModule,
  provideTranslocoLocale
} from '@jsverse/transloco-locale'
import { APP_INITIALIZER, Injectable, isDevMode, NgModule } from '@angular/core'
import { TranslateService } from './shared/services/translate.service'
import { AuthResponse } from '@peritos/module-builder'
import { firstValueFrom } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`)
  }
}

export function getUserLang(
  transloco: TranslocoService,
  translate: TranslateService
) {
  return function (): Promise<Translation> {
    const auth = localStorage.getItem('auth')
    if (auth) {
      const authObj = JSON.parse(auth) as AuthResponse
      const lang = authObj.id_ultima_idioma
      if (lang && Object.keys(translate.langKeys).includes(lang.toString())) {
        transloco.setDefaultLang(
          translate.langKeys[lang as keyof typeof translate.langKeys]
        )
        transloco.setActiveLang(
          translate.langKeys[lang as keyof typeof translate.langKeys]
        )
        return firstValueFrom(
          transloco.load(
            translate.langKeys[lang as keyof typeof translate.langKeys]
          )
        )
      }
    }

    // Try to get the language from the browser
    const lang = getBrowserLang()
    if (lang) {
      transloco.setDefaultLang(lang)
      transloco.setActiveLang(lang)
      return firstValueFrom(transloco.load(lang))
    }

    return firstValueFrom(transloco.load(transloco.getActiveLang()))
  }
}

@NgModule({
  exports: [TranslocoModule, TranslocoLocaleModule],
  providers: [
    provideTransloco({
      config: translocoConfig({
        availableLangs: ['es'],
        defaultLang: 'es',
        fallbackLang: 'es',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
        flatten: {
          aot: !isDevMode()
        }
      }),
      loader: TranslocoHttpLoader
    }),
    provideTranslocoLocale(),
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [TranslocoService, TranslateService],
      useFactory: getUserLang
    }
  ]
})
export class TranslocoRootModule {}
